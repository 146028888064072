import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { STYLES } from '../styles/constants'
import TagList from '../components/TagList'

const Tile = styled.div`
  .thumbnail {
    margin-bottom: 16px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 8px;
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 20px;
    line-height: 32px;
  }

  .bookmark-toggle {
    cursor: pointer;
    margin-top: 6px;
    margin-right: 5px;

    svg path {
      fill: ${STYLES.COLOR_PN_YELLOW};
    }
  }

  .tag-list {
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: ${STYLES.BP_SMALL_MAX}) {
    h3, .tag-list, .description {
      padding: 0 16px;
    }

    .bookmark-toggle {
      margin-right: 16px;
    }
  }
`;

export default class UpdateTile extends React.Component {
  
  render() {
    const { update } = this.props

    return (
      <Tile className="update-tile">
        <Link to={`/update/${update.slug}`} onClick={() => this.trackClick(update, 'thumbnail')}>
          <Img alt={update.title} sizes={update.thumbnail.sizes} className="thumbnail" />
        </Link>
        <div className="title-container">
          <h3><Link to={`/update/${update.slug}`} onClick={() => this.trackClick(update, 'title')}>{update.title}</Link></h3>
        </div>
        <TagList tags={update.tags}/>
      </Tile>
    )
  }
}