import styled from 'styled-components';
import { STYLES } from '../styles/constants'

export const TileGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  align-content:space-between;
  justify-content:center;

  .update-tile, .collection-tile {
    margin-bottom: 48px;
    margin-right: 30px;
  }

  .collection-tile {
    width: calc(25% - 30px);
  }

  .collection-tile:nth-of-type(4n+4) {
    margin-right: 0;
  }

  &.threeup{
    .update-tile,
    .collection-tile {
      width: calc(33.33% - 26.66px);
    }
    
    .update-tile:nth-of-type(3n+3),
    .collection-tile:nth-of-type(3n+3) {
      margin-right: 0;
    }
  }
  &.twoup{
    .update-tile,
    .collection-tile {
      width: calc(50% - 20px);
    }
    
    .update-tile:nth-of-type(2n+2),
    .collection-tile:nth-of-type(2n+2) {
      margin-right: 0;
    }
  }

  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
    flex-direction:column;

    .update-tile, .collection-tile {
      width: 100%;
    }

    &.threeup, &.twoup{
      .update-tile, .collection-tile {
        width: 100%;
        margin-right:0;
      }
    } 
  }
`;