import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Moment from 'react-moment'
import Navigation from '../components/Navigation'
import NoNavigation from '../components/NoNavigation'
import Container from '../components/container'
import UpdateTile from '../components/UpdateTile'
import { TileGrid } from '../components/TileGrid'
import { Wrapper } from '../components/Wrapper'
import styled from 'styled-components'
import { STYLES } from '../styles/constants'

const IndexHeader = styled.div`
  margin-top: 48px;
  text-align: center;
  padding: 0 ${STYLES.PADDING_MOBILE};

  .year {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_SEMIBOLD};
    font-size: 12px;
    color: ${STYLES.COLOR_PN_YELLOW_DARK};
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  h1 {
    max-width: 640px;
    margin: 0 auto 16px auto;
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 48px;
  }

  p {
    max-width: 640px;
    margin: 0 auto 16px auto;
    font-family: "Noto Sans";
    font-size: 16px;
    line-height: 28px;
  }

  .arrow {
    margin-bottom: 48px;

    svg {
      width: 16px;
      height: 17px;
    }
  }

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    margin-top: 72px;

    h1 {
      margin-bottom: 72px;
    }

    .arrow {
      margin-bottom: 72px;
    }
  }
`;

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const collections = get(this, 'props.data.allContentfulCollection.edges')
    const liveCollections = collections.filter( collection => {
      return collection.node.live
    })
    const currentCollection = liveCollections[0].node
    const lightMode = currentCollection.lightMode

    return (
      <Container>
        <Helmet title={siteTitle} bodyAttributes={{
              class: lightMode ? `light` : `dark`
          }}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        {currentCollection.hideNav && 
        <NoNavigation />
        }
        {!currentCollection.hideNav && 
        <Navigation />
        }
        <Wrapper>
          <IndexHeader>
            {currentCollection.showDate ??
              <>
              <div className="year"><Moment format="MMMM YYYY">{currentCollection.date}</Moment></div>
              </>
            }
            <h1>{currentCollection.description.description}</h1>
            {/* <p>{currentCollection.description.description}</p> */}
            {/* <div className="arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
                <g fill="none" fillRule="evenodd" transform="translate(-4 -3)">
                  <rect width="24" height="24" />
                  <polygon fill="#FBB415" fillRule="nonzero" points="20 12.357 18.59 11.01 12 17.306 5.42 11 4 12.357 12 20" />
                  <polygon fill="#FBB415" fillRule="nonzero" points="13 14 13 3 11 3 11 14 12 15" />
                </g>
              </svg>
            </div> */}
          </IndexHeader>
        </Wrapper>
        <Wrapper containerPadding="true">
          <TileGrid className={currentCollection.updates.length % 3 === 0 || currentCollection.updates.length % 5 === 0 ? "threeup" : "twoup"}>
            {currentCollection.updates.map(update => (
              <UpdateTile update={update} key={update.slug} />
            ))}
          </TileGrid>
        </Wrapper>
      </Container>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query IndexQuery {
    #SITE METADATA
    site {
      siteMetadata {
        title
      }
    }
    
    allContentfulCollection(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          date
          live
          description {
            description
          }
          updates {
            title
            slug
            tags {
              name
              id
            }
            thumbnail {
              sizes(maxWidth: 704, maxHeight: 704) {
                ...GatsbyContentfulSizes
              }
            }
          }
          live
          lightMode
          showDate
          hideNav
        }
      }
    }
  }
`
